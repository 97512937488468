import { css } from "@emotion/react";
import theme from "src/theme";
import orgDashboardStyles from "src/pages/org-dashboard/styles";

export default {
  mainHeading: css`
    ${orgDashboardStyles.mainHeading};
    color: ${theme.colors.white};
  `,
  mainContentContainer: css`
    ${orgDashboardStyles.detailsContainer};
    grid-gap: 20px 60px;

    .ant-form-item-explain {
      position: absolute;
      top: 100%;

      > div:not(.ant-form-item-explain-error) {
        opacity: 0;
      }
    }

    .ant-form-item {
      margin-bottom: 12px;

      label {
        margin-top: 1rem;
      }
    }
  `,
  modulesContentContainer: css`
    width: 65%;
    grid-gap: 20px 10px;
    grid-template-columns: repeat(4, 1fr);
  `,
  subHeading: css`
    margin-top: 3rem;
    color: ${theme.colors.white};
    font-size: 2rem;
  `,
  descriptionText: css`
    color: ${theme.colors.white};
    margin-top: 1rem;
  `,
  submitBtn: css`
    margin: 2rem 0 1rem;
  `,
};
