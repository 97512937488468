import React, { ReactElement, useMemo } from "react";
import HamburgerIcon from "src/app/assets/icons/component-icons/navigation/hamburger-menu";
import CrossIcon from "src/app/assets/icons/component-icons/navigation/cross";
import { Button } from "@songtradr/component-library";
import { Link } from "react-router-dom";
import useNavItems from "src/utils/hooks/use-nav-items";
import useProjectsSubNav from "src/utils/hooks/use-projects-sub-nav";
import { FeatureFlagStorageKeys } from "src/providers/feature-flags/state/utils";
import useFeatureFlags from "src/providers/feature-flags/hooks";
import useOrganisation from "src/providers/organisation/hooks";
import { createPortal } from "react-dom";
import appStyles from "src/app/styles";
import styles from "./styles";
import { IProps } from "./interfaces";

const MobileNavMenu = ({
  className,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  menuAnchor,
}: IProps): ReactElement | null => {
  const navData = useNavItems();
  const [showProjectsSubNav, projectsSubNavData] = useProjectsSubNav();
  const { isLoading } = useFeatureFlags();
  const { organisation } = useOrganisation();

  const hasAccessToMusicUsageDeclaration = useMemo(() => {
    return (
      !isLoading &&
      localStorage.getItem(FeatureFlagStorageKeys.MUSIC_USAGE_DECLARATION) ===
        "on"
    );
  }, [isLoading]);

  const toggleOpen = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className={className}>
      <button type="button" css={styles.button} onClick={toggleOpen}>
        {isMobileMenuOpen ? (
          <CrossIcon css={styles.closeIcon} />
        ) : (
          <HamburgerIcon />
        )}
      </button>
      {menuAnchor.current &&
        createPortal(
          <div
            css={[
              appStyles.mobileOnly,
              styles.menuContainer,
              isMobileMenuOpen ? styles.visible : styles.invisible,
            ]}
            onClick={toggleOpen}
            role="none"
          >
            <div
              css={[
                styles.menuItemContainer(isMobileMenuOpen),
                isMobileMenuOpen ? styles.visible : styles.invisible,
              ]}
              onClick={(e) => e.stopPropagation()}
              role="none"
            >
              <h2 css={styles.title}>Songtradr Music</h2>
              {organisation?.primaryLogo && (
                <img
                  css={styles.logo}
                  src={organisation?.primaryLogo}
                  alt="logo"
                />
              )}
              <section css={styles.section}>
                {showProjectsSubNav && (
                  <>
                    <div>
                      <Link
                        {...projectsSubNavData.CommercialLicenses}
                        css={styles.link}
                      >
                        Commercial Licenses
                      </Link>
                    </div>

                    {hasAccessToMusicUsageDeclaration && (
                      <div>
                        <Link
                          {...projectsSubNavData.LibraryLicenses}
                          css={styles.link}
                        >
                          Library Licenses
                        </Link>
                      </div>
                    )}
                  </>
                )}
              </section>
              <section css={styles.section}>
                {navData.Insights.hasPermission && (
                  <a css={styles.smallLink} {...navData.Insights}>
                    Insights
                  </a>
                )}

                {navData.Projects.hasPermission && (
                  <Link
                    onClick={toggleOpen}
                    css={styles.smallLink}
                    {...navData.Projects}
                  >
                    Projects
                  </Link>
                )}

                <a
                  onClick={toggleOpen}
                  css={styles.smallLink}
                  role="none"
                  {...navData.Resources}
                >
                  Resources
                </a>

                {navData.Measurement.hasPermission && (
                  <Link
                    onClick={toggleOpen}
                    css={styles.smallLink}
                    {...navData.Measurement}
                  >
                    Measurement
                  </Link>
                )}

                {navData.AdaptiveMusic.hasPermission && (
                  <Link
                    onClick={toggleOpen}
                    css={styles.smallLink}
                    {...navData.AdaptiveMusic}
                  >
                    Adaptive Music
                  </Link>
                )}
              </section>

              {navData["Organization Dashboard"].hasPermission && (
                <Link
                  css={styles.smallestLink}
                  onClick={toggleOpen}
                  {...navData["Organization Dashboard"]}
                >
                  Organization Dashboard
                </Link>
              )}

              {navData["Brand audit"].hasPermission && (
                <Link
                  css={styles.smallestLink}
                  onClick={toggleOpen}
                  {...navData["Brand audit"]}
                >
                  Brand audit
                </Link>
              )}

              {navData["Manage Team"].hasPermission && (
                <Link
                  css={styles.smallestLink}
                  onClick={toggleOpen}
                  {...navData["Manage Team"]}
                >
                  Manage Team
                </Link>
              )}

              {navData["Help Center"].hasPermission && (
                <a css={styles.smallestLink} {...navData["Help Center"]}>
                  Help center
                </a>
              )}

              <div css={styles.logoutBtnContainer}>
                <Button
                  variant="important"
                  size="fullWidth"
                  className="sign-out-link"
                  {...navData.Logout}
                >
                  Logout
                </Button>
              </div>
            </div>
          </div>,
          menuAnchor.current
        )}
    </div>
  );
};

export default MobileNavMenu;
