import React, { ReactElement, useRef, useState } from "react";
import useAuth from "src/auth/use-auth";
import Silhouette from "src/app/assets/icons/component-icons/silhouette";
import { Link } from "react-router-dom";
import { useOutsideClick } from "src/utils/hooks/use-outside-click";
import useNavItems from "src/utils/hooks/use-nav-items";
import SmallChevronIcon from "src/app/assets/icons/component-icons/small-chevron-icon";
import styles from "./styles";

const AvatarMenu = (
  props: React.HTMLAttributes<HTMLDivElement>
): ReactElement | null => {
  const [isOpen, setIsOpen] = useState(false);
  const { profileImage, user, isSuperAdmin } = useAuth();
  const menuRef = useRef(null);
  const navData = useNavItems();
  useOutsideClick(menuRef, () => setIsOpen(false));

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div css={styles.menuContainer} ref={menuRef} {...props}>
      <button
        type="button"
        css={[styles.avatarBtn, styles.avatar]}
        data-heapid="header-nav-profile-icon"
        data-testid="header-nav-avatar-icon"
        onClick={toggleOpen}
      >
        {profileImage ? (
          <img src={profileImage} alt="profile" css={styles.avatar} />
        ) : (
          <Silhouette />
        )}

        <div css={styles.chevronContainer}>
          <SmallChevronIcon />
        </div>
      </button>
      <div
        css={[styles.dropdownContainer, isOpen && styles.dropdownContainerOpen]}
      >
        <Link css={styles.userData} to={navData.Profile.to}>
          <p
            css={styles.username}
            data-testid={navData.Profile["data-testid"]}
            data-heapid={navData.Profile["data-heapid"]}
          >
            {user?.name}
          </p>
          {isSuperAdmin && <div css={styles.role}>Super Admin</div>}
        </Link>

        {navData["Organization Dashboard"].hasPermission && (
          <Link
            css={styles.link}
            onClick={toggleOpen}
            {...navData["Organization Dashboard"]}
          >
            Organization Dashboard
          </Link>
        )}

        {navData["Brand audit"].hasPermission && (
          <Link
            css={styles.link}
            onClick={toggleOpen}
            {...navData["Brand audit"]}
          >
            Brand audit
          </Link>
        )}

        {navData["Manage Team"].hasPermission && (
          <Link
            css={styles.link}
            onClick={toggleOpen}
            {...navData["Manage Team"]}
          >
            Manage Team
          </Link>
        )}

        {navData["Help Center"].hasPermission && (
          <a css={styles.link} {...navData["Help Center"]}>
            Help center
          </a>
        )}

        <button css={styles.link} type="button" {...navData.Logout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default AvatarMenu;
