import { useMemo } from "react";
import defaultUserPermissions from "src/auth/utils/user-access-permission";
import config from "src/config";
import {
  generateLibraryLink,
  generateSearchLink,
  generateSonicBrandingLink,
  hasAdaptiveMusicAccess,
  isModuleVisible,
} from "src/pages/dashboard/utils";
import useAuth from "src/auth/use-auth";
import { eApplicationType } from "src/interfaces/auth";
import useOrgSubscription from "src/providers/organisation/hooks";

const useNavItems = () => {
  const { isSuperAdmin, organisationId, userPermissions, logout } = useAuth();
  const { organisation } = useOrgSubscription();
  const permissions = useMemo(() => {
    return userPermissions ?? defaultUserPermissions;
  }, [userPermissions]);

  // (below is carried over from old navbar)
  // hardcoding so the Loreal professional account doesn't show the search link
  const isLorealProfessional = organisationId === "6319cb6dc80bc8cf4fa3e25f";

  // Pass any attributes to the links here, they'll be added
  // to both the mobile and desktop navs
  return {
    Library: {
      hasPermission: isModuleVisible(
        organisation,
        permissions.canAccessMusicSubscription,
        eApplicationType.LibraryFull
      ),
      "data-heapid": "nav-link-playlists",
      "data-testid": "nav-link-playlists",
      // If org is not listed then use the new player: https://player.enterprise.songtradr.com
      href: config.oldPlayerOrgIds.includes(organisationId)
        ? config.player.url
        : generateLibraryLink(organisationId),
    },
    "My Music": {
      "data-heapid": "nav-link-my-music",
      "data-testid": "nav-link-my-music",
      href: `${config.player.url}/my-music/playlists`,
    },
    Search: {
      hasPermission:
        !isLorealProfessional &&
        isModuleVisible(
          organisation,
          permissions.canAccessMusicSubscription,
          eApplicationType.LibraryFull
        ),
      "data-heapid": "nav-link-search",
      "data-testid": "nav-link-search",
      href: generateSearchLink(organisationId),
    },
    Connect: {
      "data-heapid": "nav-link-connect",
      "data-testid": "nav-link-connect",
      href: `${config.player.url}/connect`,
    },
    AdaptiveMusic: {
      hasPermission: hasAdaptiveMusicAccess(organisation),
      "data-heapid": "nav-link-adaptive-music",
      "data-testid": "nav-link-adaptive-music",
      to: "/adaptivemusic",
    },
    Insights: {
      hasPermission: isModuleVisible(
        organisation,
        permissions.canAccessInsights,
        eApplicationType.Insights
      ),
      "data-heapid": "nav-link-insights",
      "data-testid": "nav-link-insights",
      href: `${config.player.url}/charts`,
    },
    Projects: {
      hasPermission: isModuleVisible(
        organisation,
        permissions.canAccessProjects,
        eApplicationType.ProjectsClient
      ),
      "data-heapid": "nav-link-projects",
      "data-testid": "nav-link-projects",
      to: "/commerciallicenses",
    },
    Resources: {
      hasPermission: isModuleVisible(
        organisation,
        permissions.canAccessMyContent,
        eApplicationType.Resources
      ),
      "data-heapid": "nav-link-resources",
      "data-testid": "nav-link-resources",
      href: generateSonicBrandingLink(organisationId),
    },
    Measurement: {
      hasPermission:
        config.featureToggles.showValidateFeature &&
        isModuleVisible(
          organisation,
          permissions.canAccessValidate,
          eApplicationType.Validate
        ),
      "data-heapid": "nav-link-measurement",
      "data-testid": "nav-link-measurement",
      to: "/measurement",
    },
    "Organization Dashboard": {
      hasPermission: isSuperAdmin,
      "data-testid": "nav-link-admin-org-dash",
      to: "/admin/organization-dashboard",
    },
    "Brand audit": {
      hasPermission: isSuperAdmin,
      "data-testid": "nav-link-admin-brand-audit",
      to: "/admin/brand-audit",
    },
    "Manage Team": {
      hasPermission: permissions.canAccessTeams,
      "data-heapid": "nav-link-team",
      "data-testid": "nav-link-team",
      to: "/team",
    },
    "Help Center": {
      hasPermission: !organisation?.enableCscMode,
      "data-heapid": "nav-link-support",
      "data-testid": "nav-link-support",
      href: config.support.url,
      target: "_self",
    },
    Profile: {
      "data-heapid": "header-nav-profile-name",
      "data-testid": "header-nav-user-name",
      to: "/profile",
    },
    Logout: {
      "data-heapid": "nav-link-sign-out",
      "data-testid": "nav-link-sign-out",
      onClick: logout,
    },
  };
};

export default useNavItems;
