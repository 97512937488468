import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  container: css`
    position: relative;
    background: ${theme.colors.background.landingDarkGray};
    color: ${theme.colors.white};
  `,
  innerContainer: css`
    position: relative;
    z-index: 1;
    max-width: 1552px;
    padding: 1rem 24px;
    margin: auto;
    .ant-form-item-label > label {
      font-size: 1rem;
    }
    .section-header {
      color: ${theme.colors.white};
      font-family: ${theme.fonts.sofiaPro};
    }
    .delete-button {
      background: transparent !important;
      border: none;
      cursor: pointer;

      :hover {
        svg path {
          fill: ${theme.colors.functional.brightOrange} !important;
        }
      }
    }
  `,
  animationContainer: css`
    position: absolute;
    top: 100px;
    right: 0;
  `,
  headerContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.5rem;
  `,
  mainHeading: css`
    margin: 0;
    color: inherit;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 2.5rem;
  `,
  mainContentContainer: css`
    display: flex;
    margin-top: 2rem;
  `,
  modalTitle: css`
    color: ${theme.colors.white} !important;
  `,
  modalContent: css`
    color: ${theme.colors.white};
    ul {
      li {
        margin: 1rem 0 1rem 0;
      }
    }
  `,
  leftContainer: css`
    flex: none;
    width: 500px;
  `,
  rightContainer: css`
    margin-left: 2rem;
    flex-grow: 1;
    min-width: 0;
  `,
  activeTabContainer: css`
    margin-top: 1rem;
    .ant-form-item-label > label {
      font-size: 1rem;
    }
  `,
  tabContainer: css`
    display: flex;
    justify-content: flex-end;
  `,
  tabBtn: css`
    appearance: none;
    margin-left: 2rem;
    background: transparent;
    cursor: pointer;
    padding-bottom: 0.5rem;
    border: 0;
    border-bottom: 2px solid transparent;
  `,
  tabBtnActive: css`
    border-bottom: 2px solid ${theme.colors.functional.brightOrange};
  `,
  underlinedBtn: css`
    margin-left: 2rem;
  `,
  logoContainer: css`
    position: relative;
    .columns {
      display: flex;
      flex-direction: row;
      align-items: left;
      div:nth-of-type(1) {
        margin-right: 75px;
      }
      .delete-button {
        display: block;
        margin-top: 15px;
      }

      .product-info {
        width: 100%;
      }
    }
    font-family: ${theme.fonts.sofiaPro};
    h2 {
      color: ${theme.colors.white};
    }
    .product-info-heading {
      margin-top: 35px;
    }
    .org-logo {
      height: 128px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  `,
  guidelineText: css`
    width: 240px;
    margin-top: 0.5rem;
    margin-right: 0 !important;
  `,
  linkText: css`
    text-decoration: underline;
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    background: none;
    border: none;
    font-weight: 700;

    :hover {
      color: ${theme.colors.functional.brightOrange};
    }
  `,
  subHeading: css`
    margin-top: 2.25rem;
    color: ${theme.colors.white};
  `,
  descriptionText: css`
    color: ${theme.colors.white};
    margin-top: 1rem;
  `,
  checkbox: css`
    margin-top: 1rem;
  `,
  detailsContainer: css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    font-family: ${theme.fonts.sofiaPro};

    .ant-form-item {
      margin-bottom: 12px;
    }

    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;

      .ant-checkbox {
        top: 0;
      }
    }

    .ant-form-item-control {
      position: relative;

      .ant-form-item-explain {
        position: absolute;
        top: 100%;

        > div:not(.ant-form-item-explain-error) {
          opacity: 0;
        }
      }
    }

    label,
    span {
      color: ${theme.colors.white};
      height: fit-content;
    }

    input {
      color: ${theme.colors.white} !important;
      background: #1a1a1a;
      border-color: #2a2a2a !important;

      &.ant-input-status-error {
        background: #1a1a1a !important;
      }

      &:read-only {
        color: hsl(0, 0%, 40%) !important;
      }
    }
  `,
  submitBtn: css`
    margin-top: 1rem;
  `,
};
