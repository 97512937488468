import {
  generateLibraryLink,
  generateSearchLink,
  generateSonicBrandingLink,
} from "src/pages/dashboard/utils";
import config from "src/config";

interface IModulesVisibility {
  search: boolean;
  library: boolean;
  projects: boolean;
  measurements: boolean;
  adaptiveMusic: boolean;
  insights: boolean;
  resources: boolean;
}

export enum EModules {
  SEARCH = "Studio Search",
  LIBRARY = "Studio Playlists",
  PROJECTS = "Projects",
  MEASUREMENTS = "Measurements",
  ADAPTIVE_MUSIC = "Adaptive Music",
  INSIGHTS = "Insights",
  RESOURCES = "Resources",
}

const getModulesData = (
  organisationId: string,
  modulesVisibility: IModulesVisibility,
  isCSCMode?: boolean
) => {
  return [
    {
      title: EModules.SEARCH,
      description: "Search for music using our industry leading AI platform.",
      link: generateSearchLink(organisationId, isCSCMode),
      externalLink: true,
      openInSameWindow: true,
      visibility: modulesVisibility.search || isCSCMode,
    },
    {
      title: EModules.LIBRARY,
      description:
        "Search for music or explore our expertly curated playlists.",
      link: generateLibraryLink(organisationId, isCSCMode),
      externalLink: true,
      openInSameWindow: true,
      visibility: modulesVisibility.library && !isCSCMode,
    },
    {
      title: EModules.PROJECTS,
      description:
        "Track the status of your music licenses and creative service requests.",
      link: "/commerciallicenses",
      externalLink: false,
      visibility: modulesVisibility.projects && !isCSCMode,
    },
    {
      title: EModules.RESOURCES,
      description:
        "Access your creative service assets effortlessly from the resource hub.",
      link: generateSonicBrandingLink(organisationId),
      externalLink: true,
      openInSameWindow: true,
      visibility: modulesVisibility.resources && !isCSCMode,
    },
    {
      title: EModules.MEASUREMENTS,
      description:
        "Measure your music choices with data from real audiences to ensure the right choices.",
      link: "/measurement",
      externalLink: false,
      visibility: modulesVisibility.measurements && !isCSCMode,
    },
    {
      title: EModules.INSIGHTS,
      description:
        "Check artist and song performance for brand and audience fit.",
      link: `${config.player.url}/charts`,
      externalLink: true,
      visibility: modulesVisibility.insights && !isCSCMode,
    },
    {
      title: EModules.ADAPTIVE_MUSIC,
      description:
        "Dynamically edit and compose tracks in real time with our generative AI solution.",
      link: "/adaptivemusic",
      externalLink: false,
      visibility: modulesVisibility.adaptiveMusic && !isCSCMode,
    },
  ].filter((module) => module.visibility);
};

export { getModulesData };
