/* eslint-disable @typescript-eslint/no-shadow */
import { Select as SelectBase, Tag } from "antd";
import { BaseSelectRef } from "rc-select";
import styled from "styled-components";
import React, { Ref } from "react";
import { ISelectProps } from "./ts";
import styles from "./styles";
import CloseIcon from "./icons/close-icon";

const InviteSelect = React.forwardRef(
  (props: ISelectProps, ref: Ref<BaseSelectRef> | undefined) => {
    // REDUCE PROPS FOR ANT SELECT
    const {
      label,
      isTags,
      helperText,
      isValidTag,
      className = "select-compopnent",
      ...reducedProps
    } = props;

    const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
      event.preventDefault();
      const pastedData = event.clipboardData.getData("text");
      const separatedItems = pastedData.split(",").map((item) => item.trim());

      if (reducedProps.onChange) {
        reducedProps.onChange([
          ...new Set([...(reducedProps.value || []), ...separatedItems]),
        ]);
      }
    };

    const tagRender = (tagProps: {
      label: any;
      value: any;
      closable: any;
      onClose: any;
    }) => {
      const { label, value, closable, onClose } = tagProps;
      let tagClassName = "valid-tag";
      if (isValidTag && !isValidTag(value)) tagClassName = "invalid-tag";

      return (
        <Tag
          color={value}
          closable={closable}
          onClose={onClose}
          className={tagClassName}
          closeIcon={<CloseIcon height={9} width={9} role="button" />}
        >
          {label}
        </Tag>
      );
    };

    // RENDER
    return (
      <div className={className} onPaste={handlePaste}>
        {label && <div className="input-label">{label}</div>}
        {isTags ? (
          <SelectBase
            {...reducedProps}
            ref={ref}
            bordered={false}
            mode="tags"
            dropdownStyle={{ display: "none" }}
            tagRender={tagRender}
          />
        ) : (
          <SelectBase {...reducedProps} ref={ref} bordered={false} />
        )}
        {helperText && <div className="helper-text">{helperText}</div>}
      </div>
    );
  }
);

export default styled(InviteSelect)(styles);
