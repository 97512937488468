import { getIsMobile } from "@songtradr/spa-common/lib/utils";
import { Form } from "antd";
import { RuleObject } from "antd/lib/form";
import React, { Fragment, ReactElement, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import sendOrganisationInvites, {
  IOrgInvitesResponse,
} from "src/api/organisation-invites/send-org-invites";
import ModalSuccessIcon from "src/app/assets/icons/component-icons/modal-success";
import useAuth from "src/auth/use-auth";
import { errorToast } from "src/components/toast-notification";
import { eApplicationType } from "src/interfaces/auth";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import { Button } from "@songtradr/component-library";
import getApplicationsPermissions from "src/pages/team/utils";
import ValidateEmails from "./email-validator";
import styles from "./styles";
import InviteSelect from "../../../invite-select";
import RolesAndPermission from "./roles-and-permissions";

interface IProps {
  handleToggle: () => void;
  setInvitesLastUpdatedAt: (date: Date) => void;
  allApplications: eApplicationType[];
}

enum InviteFlowState {
  InviteStage = "InviteStage",
  RolesAndPermissionStage = "RolesAndPermissionStage",
  SuccessStage = "SuccessStage",
  ErrorStage = "ErrorStage",
}

const InviteMembers = ({
  handleToggle,
  setInvitesLastUpdatedAt,
  allApplications,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { organisationId, getAccessToken } = useAuth();

  const [inviteErrors, setInviteErrors] = useState<IOrgInvitesResponse>();
  const [invitedEmails, setInvitedEmails] = useState<string[]>([]);
  const [emailInviteCount, setEmailInviteCount] = useState<number>(0);
  const [contentStage, setContentStage] = useState<InviteFlowState>(
    InviteFlowState.InviteStage
  );

  const handleChange = (emailInvites: string[]) => {
    setInvitedEmails(emailInvites);
  };
  const ref = useRef<string>("");
  const isMobile = getIsMobile();

  const FIELDS = {
    emailAddresses: ["addEmailAddresses"],
    permissions: "selectPermissions",
  };

  const handleCancel = () => {
    setInvitedEmails([]);
    setEmailInviteCount(0);
    setContentStage(InviteFlowState.InviteStage);
    form.resetFields();
    handleToggle();
  };

  const handleFormSubmit = async (selectedPermissions: eApplicationType[]) => {
    try {
      if (!organisationId) {
        throw Error("Undefined organizationId");
      }

      const accessToken = getAccessToken();
      const {
        isApplicationAdmin,
        additionalApplications,
      } = getApplicationsPermissions(allApplications, selectedPermissions);

      const { data } = await sendOrganisationInvites(
        organisationId,
        accessToken,
        {
          application: eApplicationType.Portal,
          organisationId,
          emails: invitedEmails,
          additionalApplications,
          isApplicationAdmin,
        }
      );

      setInviteErrors(data);
      setContentStage(
        data.existingInvites.length ||
          data.invalidEmails.length ||
          data.existingUsers.length
          ? InviteFlowState.ErrorStage
          : InviteFlowState.SuccessStage
      );
      setInvitesLastUpdatedAt(new Date());
      form.resetFields();
    } catch (error) {
      log(DataDogLogTypes.ERROR, "Invite members error", error);

      errorToast({
        message: t(
          "inviteMembers##An error has ocurred when inviting members. Please try again."
        ),
      });
    }
  };

  const isValidEmail = (email: string) => ValidateEmails([email]).successful;

  const getInviteButtonText = (): string => {
    if (contentStage === InviteFlowState.InviteStage)
      return `${t("inviteMembers##Next")}${
        emailInviteCount === 0 ? "" : ` (${emailInviteCount})`
      }`;
    return `${t("inviteMembers##Send invite")}${
      emailInviteCount === 0 ? "" : ` (${emailInviteCount})`
    }`;
  };

  const setNativeValue = (element: HTMLInputElement, value: string) => {
    const nativeInputValueSetter = Object?.getOwnPropertyDescriptor(
      window?.HTMLInputElement?.prototype,
      "value"
    )?.set;

    nativeInputValueSetter?.call(element, value);

    const ev2 = new Event("input", { bubbles: true });
    element.dispatchEvent(ev2);
  };

  const handleInputClick = () => {
    const emailInput = document.getElementById(FIELDS.emailAddresses[0]);

    if (ref.current === "") return;

    const removeDraft = invitedEmails.some((el) => {
      return el === ref.current;
    });

    if (removeDraft) {
      ref.current = "";
      return;
    }

    if (emailInput && ref.current) {
      setNativeValue(emailInput as HTMLInputElement, ref.current);
    }
  };

  const getStageComponent = (): ReactElement => {
    switch (contentStage) {
      case InviteFlowState.InviteStage:
        return (
          <div data-testid="invite-members" css={styles.inviteMembersContainer}>
            <div css={styles.header}>{t("inviteMembers##Invite members")}</div>
            <div css={styles.subtitle}>
              {t(
                "inviteMembers##Invite members to your organization by pasting in their email addresses"
              )}
            </div>
            <div css={styles.subtitle}>
              You can upload up to 100 email addresses at a time, separated by
              comma; &quot;one@example.com, two@example.com&quot;
            </div>
            <Form
              form={form}
              onFinish={() =>
                setContentStage(InviteFlowState.RolesAndPermissionStage)
              }
              layout="vertical"
              css={styles.formContainer}
            >
              <div css={styles.contentContainer}>
                <Form.Item
                  name={FIELDS.emailAddresses}
                  rules={[
                    {
                      validator: (rule: RuleObject, emails: string[]) => {
                        try {
                          if (emails === undefined || emails.length === 0) {
                            setEmailInviteCount(0);
                            throw new Error(
                              t("inviteMembers##validation##EnterAnEmail")
                            );
                          }

                          if (emails.length > 100) {
                            throw new Error(
                              t("inviteMembers##validation##UpToHundredEmails")
                            );
                          }

                          const result = ValidateEmails(emails);
                          setEmailInviteCount(result.validEmailCount);

                          if (!result.successful) {
                            throw new Error(t(result.errorMessageKey));
                          }
                          return Promise.resolve();
                        } catch (error) {
                          return Promise.reject(error);
                        }
                      },
                    },
                  ]}
                >
                  <InviteSelect
                    isTags
                    label={t("inviteMembers##Add email address")}
                    helperText={t("inviteMembers##Hit enter to add")}
                    value={invitedEmails}
                    onChange={handleChange}
                    listHeight={3000}
                    onClick={handleInputClick}
                    onInputKeyDown={(c: any) => {
                      // Wait for latest input value on key down
                      setTimeout(() => {
                        ref.current = (c.target as HTMLInputElement).value;
                      }, 5);

                      if (c.code === "Enter") {
                        const emailInput = document.getElementById(
                          FIELDS.emailAddresses[0]
                        );
                        ref.current = "";
                        emailInput?.blur();
                        emailInput?.focus();
                        c.preventDefault();
                        c.stopPropagation();
                      }
                    }}
                    isValidTag={isValidEmail}
                  />
                </Form.Item>
              </div>
              <div
                css={
                  isMobile
                    ? styles.mobileButtonContainer
                    : styles.tabletDesktopButtonContainer
                }
              >
                <Button
                  onClick={handleCancel}
                  className="inviteMembersCancelButton"
                  variant="subtle"
                  css={styles.cancelBtn}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  variant="primary"
                  data-testid="send-invite-button"
                  className="inviteMembersNextButton"
                  disabled={
                    invitedEmails.length === 0 ||
                    !ValidateEmails(invitedEmails).successful
                  }
                >
                  {getInviteButtonText()}
                </Button>
              </div>
            </Form>
          </div>
        );
      case InviteFlowState.RolesAndPermissionStage:
        return (
          <RolesAndPermission
            allApplications={allApplications}
            inviteButtonText={getInviteButtonText()}
            isMobile={isMobile}
            onCancel={handleCancel}
            onFormSubmit={handleFormSubmit}
          />
        );
      case InviteFlowState.ErrorStage:
        return (
          <div
            css={[
              styles.inviteSent,
              styles.inviteMembersContainer,
              styles.inviteSentWithErrors,
            ]}
            data-testid="invite-error-modal"
          >
            <div>
              <ModalSuccessIcon title={t("inviteMembers##Remove email")} />
              <div css={styles.inviteSentHeader}>
                {t("inviteMembers##Invites sent with issues")}
              </div>
              {inviteErrors?.invalidEmails.length && (
                <div css={styles.errorBlock}>
                  <p>The following were invalid email addresses:</p>
                  <ul>
                    {inviteErrors.invalidEmails.map((invalidEmail) => (
                      <li key={`invalid-email-${invalidEmail}`}>
                        {invalidEmail}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {inviteErrors?.existingUsers.length && (
                <div css={styles.errorBlock}>
                  <p>These users already have an account:</p>
                  <ul>
                    {inviteErrors.existingUsers.map((existingUsers) => (
                      <li key={`already-user-${existingUsers}`}>
                        {existingUsers}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {inviteErrors?.existingInvites.length && (
                <div css={styles.errorBlock}>
                  <p>These users already have an invite:</p>
                  <ul>
                    {inviteErrors.existingInvites.map((existingEmail) => (
                      <li key={`existing-invite-${existingEmail}`}>
                        {existingEmail}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div
              css={
                isMobile
                  ? styles.mobileButtonContainer
                  : styles.tabletDesktopButtonContainer
              }
            >
              {!isMobile && (
                <Button
                  variant="subtle"
                  css={styles.cancelBtn}
                  onClick={handleCancel}
                  data-testid="close-modal"
                  className="inviteSentCloseButton"
                >
                  {t("Close")}
                </Button>
              )}
              <Button
                variant="primary"
                onClick={() => {
                  setInvitedEmails([]);
                  setEmailInviteCount(0);
                  ref.current = "";
                  form.resetFields();
                  setContentStage(InviteFlowState.InviteStage);
                }}
              >
                {t("inviteMembers##Invite another member")}
              </Button>
            </div>
          </div>
        );
      case InviteFlowState.SuccessStage:
        return (
          <div
            css={[styles.inviteSent, styles.inviteMembersContainer]}
            data-testid="invite-sent-modal"
          >
            <div>
              <ModalSuccessIcon title={t("inviteMembers##Remove email")} />
              <div css={styles.inviteSentHeader}>
                {t("inviteMembers##Invite sent")}
              </div>
            </div>
            <div
              css={
                isMobile
                  ? styles.mobileButtonContainer
                  : styles.tabletDesktopButtonContainer
              }
            >
              {!isMobile && (
                <Button
                  variant="subtle"
                  css={styles.cancelBtn}
                  data-testid="close-modal"
                  onClick={handleCancel}
                  className="inviteSentCloseButton"
                >
                  {t("Close")}
                </Button>
              )}
              <Button
                variant="primary"
                onClick={() => {
                  setInvitedEmails([]);
                  setEmailInviteCount(0);
                  ref.current = "";
                  form.resetFields();
                  setContentStage(InviteFlowState.InviteStage);
                }}
              >
                {t("inviteMembers##Invite another member")}
              </Button>
            </div>
          </div>
        );
      default:
        return <div />;
    }
  };

  return <Fragment>{getStageComponent()}</Fragment>;
};

export default InviteMembers;
