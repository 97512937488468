import React, { ReactElement, useEffect, useRef, useState } from "react";
import Lottie from "react-lottie-player";
import backgroundLinesAnimation from "src/app/assets/animations/bars_bg.json";
import { useHistory } from "react-router-dom";
import { Checkbox, Form, Input } from "antd";
import { Button } from "@songtradr/component-library";
import useAuth from "src/auth/use-auth";
import createOrgWithSubscriptions from "src/pages/create-new-org/utils";
import selfOrganisations, {
  IOrganisationSearchResponse,
} from "src/api/organisation/self";
import { errorToast, successToast } from "src/components/toast-notification";
import orgDashboardStyles from "src/pages/org-dashboard/styles";
import orgTableStyles from "src/pages/org-dashboard/components/org-table/styles";
import ModuleCheckboxes from "src/pages/org-dashboard/components/module-checkboxes";
import useServicingOrgDropdown from "src/pages/org-dashboard/components/organisation-dropdown/useServicingOrgDropdown";
import Select from "react-select";
import axios from "axios";
import STLoadingLogo from "src/components/st-loading-logo";
import styles from "./styles";
import OrganisationDropdown, {
  IOption,
} from "../org-dashboard/components/organisation-dropdown";
import { CurrencyCode } from "../org-dashboard/components/details-section/interfaces";
import dropdownStyles from "../org-dashboard/components/organisation-dropdown/styles";

export const defaultDealId = "5535689894";
const colSpan = { span: 24 };

const CreateNewOrg = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAdminInfoRequired, setIsAdminInfoRequired] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const { getAccessToken, isSuperAdmin, isBlessedSuperAdmin } = useAuth();
  const abortController = useRef<AbortController>();
  const [
    orgDropdownData,
    setOrgDropdownData,
  ] = useState<IOrganisationSearchResponse>();
  const { excludedServicingOrgs, onlyServicingOrgs } = useServicingOrgDropdown(
    orgDropdownData?.items
  );

  const [selectedServiceOrg, setSelectedServiceOrg] = useState<IOption>({
    value: "",
    label: "",
  });

  const [selectedParentOrg, setSelectedParentOrg] = useState<IOption>({
    value: "",
    label: "",
  });

  const [selectedHoldingOrg, setSelectedHoldingOrg] = useState<IOption>({
    value: "",
    label: "",
  });

  const [selectedCurrency, setSelectedCurrency] = useState<IOption>({
    value: "",
    label: "",
  });

  const [
    naturalLanguageSearchVersion,
    setNaturalLanguageSearchVersion,
  ] = useState<IOption>({
    value: "Internal",
    label: "Internal",
  });

  useEffect(() => {
    form.setFieldsValue({
      dealId: defaultDealId,
    });

    setIsLoading(true);
    abortController.current = new AbortController();

    (async () => {
      try {
        const orgDropdownResponse = await selfOrganisations(
          getAccessToken(),
          abortController.current
        );

        if (orgDropdownResponse) {
          setOrgDropdownData(orgDropdownResponse);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.message === "canceled") {
            return;
          }

          // eslint-disable-next-line no-console
          console.error(error);
          errorToast({ message: error.message });
        }
      } finally {
        setIsLoading(false);
      }
    })();

    return () => abortController.current?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      servicingOrganisationId: selectedServiceOrg.value,
      parentOrganisationId: selectedParentOrg.value,
      holdingOrganisationId: selectedHoldingOrg.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServiceOrg, selectedParentOrg, selectedHoldingOrg]);

  const onFormSubmit = async (values: any) => {
    try {
      await createOrgWithSubscriptions(
        values,
        getAccessToken(),
        abortController.current
      );
      successToast({ message: "Organization was successfully created" });
      history.push("/admin/organization-dashboard");
    } catch (error) {
      const defaultErrorMessage = "Failed to create organization";
      if (axios.isAxiosError(error)) {
        errorToast({
          message:
            error.response?.data?.Message ??
            error.message ??
            defaultErrorMessage,
        });
        // eslint-disable-next-line no-console
        console.error(error);
      } else if (error instanceof Error) {
        errorToast({ message: error.message ?? defaultErrorMessage });
      } else {
        errorToast({ message: defaultErrorMessage });
      }
    }
  };

  const onAdminInfoChange = () => {
    setIsAdminInfoRequired(
      form.getFieldValue("firstName") ||
        form.getFieldValue("lastName") ||
        form.getFieldValue("email") ||
        form.getFieldValue("phone")
    );
  };

  const currencyCodes = Object.keys(CurrencyCode)
    .map((currency) => ({
      value: currency,
      label: currency,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div css={orgDashboardStyles.container}>
      <div css={orgDashboardStyles.animationContainer}>
        <Lottie
          animationData={backgroundLinesAnimation}
          style={{ width: 1000, height: 175 }}
          loop
          play
        />
      </div>
      <div css={orgDashboardStyles.innerContainer}>
        <Form form={form} onFinish={onFormSubmit}>
          <div css={orgDashboardStyles.headerContainer}>
            <h1 css={styles.mainHeading}>Create new organization</h1>
            <Button
              variant="primary"
              onClick={() => history.push("/admin/organization-dashboard")}
              data-testid="back-btn"
            >
              Back
            </Button>
          </div>
          {isLoading ? (
            <div css={orgTableStyles.loadingSpinnerContainer}>
              <STLoadingLogo pageCentered relativePosition />
            </div>
          ) : (
            <>
              <h2 css={styles.subHeading}>Organization details</h2>
              <div css={styles.mainContentContainer}>
                <Form.Item
                  name="companyName"
                  label="Company name"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  validateTrigger="onSubmit"
                  help="This field is required."
                >
                  <Input aria-label="Company name" />
                </Form.Item>
                <Form.Item
                  name="parentOrganisationId"
                  label="Parent organization ID"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                >
                  <OrganisationDropdown
                    id="parentOrganisation"
                    setValue={setSelectedParentOrg}
                    orgDropdownData={excludedServicingOrgs}
                    selectedOrg={selectedParentOrg}
                  />
                </Form.Item>
                <Form.Item
                  name="holdingOrganisationId"
                  label="Holding organization ID"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                >
                  <OrganisationDropdown
                    id="holdingOrganisation"
                    setValue={setSelectedHoldingOrg}
                    orgDropdownData={excludedServicingOrgs}
                    selectedOrg={selectedHoldingOrg}
                  />
                </Form.Item>
                <Form.Item
                  name="servicingOrganisationId"
                  key="servicingOrganisationId"
                  label="Servicing organization ID"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  validateTrigger="onSubmit"
                  help="This field is required."
                >
                  <OrganisationDropdown
                    id="servicingOrganisation"
                    setValue={setSelectedServiceOrg}
                    orgDropdownData={onlyServicingOrgs}
                    selectedOrg={selectedServiceOrg}
                  />
                </Form.Item>
                <Form.Item
                  name="servicingOrganisationEmail"
                  label="Servicing organization email"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  validateTrigger="onSubmit"
                  help="This field is required."
                >
                  <Input aria-label="Servicing organization email" />
                </Form.Item>
                <Form.Item
                  name="dealId"
                  label="Deal ID"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  validateTrigger="onSubmit"
                  help="This field is required."
                >
                  <Input aria-label="Deal ID" />
                </Form.Item>
                {isSuperAdmin && (
                  <Form.Item
                    name="enablePasscodes"
                    labelCol={colSpan}
                    wrapperCol={colSpan}
                    valuePropName="checked"
                    initialValue
                  >
                    <Checkbox
                      id="enablePasscodes"
                      aria-label="Enable Passcodes"
                    >
                      Enable Passcodes
                    </Checkbox>
                  </Form.Item>
                )}
              </div>
              <h2 css={styles.subHeading}>Price estimation</h2>
              <div css={styles.mainContentContainer}>
                <Form.Item
                  name="isPublicCompany"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                  valuePropName="checked"
                >
                  <Checkbox aria-label="Public Company">
                    Is public company
                  </Checkbox>
                </Form.Item>
                {/** Dummy element to occupy a grid space */}
                <span />
                <Form.Item
                  name="marketSymbol"
                  label="Market symbol"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                >
                  <Input aria-label="Market symbol" />
                </Form.Item>
                <Form.Item
                  name="marketCap"
                  label="Market cap"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                >
                  <Input aria-label="Market cap" type="number" />
                </Form.Item>
                <Form.Item
                  name="numberOfEmployees"
                  label="Number of employees"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                >
                  <Input aria-label="Number of employees" type="number" />
                </Form.Item>
                <Form.Item
                  name="tiktokFollowerCount"
                  label="TikTok follower count"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                >
                  <Input aria-label="TikTok follower count" type="number" />
                </Form.Item>
                <Form.Item
                  name="instagramFollowerCount"
                  label="Instagram follower count"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                >
                  <Input aria-label="Instagram follower count" type="number" />
                </Form.Item>
                <Form.Item
                  name="currency"
                  label="Currency"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                  css={dropdownStyles.dropdownContainer}
                >
                  <Select
                    options={currencyCodes}
                    onChange={(value) => {
                      if (value) {
                        setSelectedCurrency(value);
                      }
                    }}
                    className="org-dropdown"
                    classNamePrefix="org-options"
                    defaultValue={selectedCurrency}
                  />
                </Form.Item>
              </div>
              <h2 css={styles.subHeading}>Account manager details</h2>
              <div css={styles.mainContentContainer}>
                <Form.Item
                  name="firstName"
                  label="First name"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                  rules={[
                    {
                      required: isAdminInfoRequired,
                    },
                  ]}
                  validateTrigger="onSubmit"
                  help="This field is required."
                >
                  <Input onChange={onAdminInfoChange} aria-label="First name" />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Last name"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                  rules={[
                    {
                      required: isAdminInfoRequired,
                    },
                  ]}
                  help="This field is required."
                >
                  <Input onChange={onAdminInfoChange} aria-label="Last name" />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                  rules={[
                    {
                      required: isAdminInfoRequired,
                    },
                  ]}
                  validateTrigger="onSubmit"
                  help="This field is required."
                >
                  <Input onChange={onAdminInfoChange} aria-label="Email" />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label="Phone"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                >
                  <Input onChange={onAdminInfoChange} aria-label="Phone" />
                </Form.Item>
              </div>
              <h2 css={styles.subHeading}>Music Search</h2>
              <div css={styles.mainContentContainer}>
                <Form.Item
                  name="enableCommercialMusicSearch"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                  valuePropName="checked"
                >
                  <Checkbox
                    id="enableCommercialMusicSearch"
                    aria-label="Enable commercial music search"
                  >
                    Enable commercial music search
                  </Checkbox>
                </Form.Item>
                {/** Dummy element to occupy a grid space */}
                <span />
                <Form.Item
                  name="enableBriefSearch"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                  valuePropName="checked"
                  initialValue
                >
                  <Checkbox
                    id="enableBriefSearch"
                    aria-label="Enable brief search"
                  >
                    Enable brief search
                  </Checkbox>
                </Form.Item>
                {/** Dummy element to occupy a grid space */}
                <span />
                <Form.Item
                  name="naturalLanguageSearchVersion"
                  label="Natural Language Search Version"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                  css={dropdownStyles.dropdownContainer}
                >
                  <Select
                    options={[
                      {
                        label: "Internal",
                        value: "Internal",
                      },
                      {
                        label: "ChatGPT",
                        value: "ChatGPT",
                      },
                    ]}
                    onChange={(value) => {
                      if (value) {
                        setNaturalLanguageSearchVersion(value);
                      }
                    }}
                    className="org-dropdown"
                    classNamePrefix="org-options"
                    defaultValue={naturalLanguageSearchVersion}
                  />
                </Form.Item>
              </div>
              <h2 css={styles.subHeading}>Adaptive Music</h2>
              <div css={styles.mainContentContainer}>
                <Form.Item
                  name="enableAdaptiveMusic"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                  valuePropName="checked"
                >
                  <Checkbox
                    id="enableAdaptiveMusic"
                    aria-label="Enable Adaptive Music"
                  >
                    Enable Adaptive Music
                  </Checkbox>
                </Form.Item>
                {/** Dummy element to occupy a grid space */}
                <span />
                <Form.Item
                  name="adaptiveMusicUrl"
                  label="Adaptive Music URL"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                >
                  <Input aria-label="Adaptive Music URL" type="url" />
                </Form.Item>
                {/** Dummy element to occupy a grid space */}
                <span />
              </div>
              <h2 css={styles.subHeading}>Video Sync</h2>
              <div css={styles.mainContentContainer}>
                <Form.Item
                  name="enableVideoSync"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                  valuePropName="checked"
                >
                  <Checkbox id="enableVideoSync" aria-label="Enable Video Sync">
                    Enable Video Sync
                  </Checkbox>
                </Form.Item>
                {/** Dummy element to occupy a grid space */}
                <span />
              </div>
              {isBlessedSuperAdmin && (
                <>
                  <h2 css={styles.subHeading}>
                    Commercial Supply Chain Mode (CSC)
                  </h2>
                  <div css={styles.mainContentContainer}>
                    <Form.Item
                      name="enableCscMode"
                      labelCol={colSpan}
                      wrapperCol={colSpan}
                      valuePropName="checked"
                      label="CSC allows customers to create custom playlists of
                    commercial tracks for integration into their platforms via
                    API (e.g., Peloton workout music)."
                    >
                      <Checkbox id="enableCscMode" aria-label="Enable CSC Mode">
                        Enable CSC Mode
                      </Checkbox>
                    </Form.Item>
                    {/** Dummy element to occupy a grid space */}
                    <span />
                  </div>
                  <h3 css={styles.descriptionText}>
                    <strong>Important Note: </strong>
                    Standard Studio organizations <strong>SHOULD NOT </strong>
                    have CSC Mode enabled. The two modes are not compatible.
                  </h3>
                </>
              )}
              <h2 css={styles.subHeading}>Licensing</h2>
              <div css={styles.mainContentContainer}>
                <Form.Item
                  name="licensingOverviewDocumentUrl"
                  label="Licensing Overview Document Location"
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                >
                  <Input
                    aria-label="Licensing Overview Document Location"
                    type="url"
                  />
                </Form.Item>
              </div>
              <h2 css={styles.subHeading}>Modules</h2>
              <div
                css={[
                  styles.mainContentContainer,
                  styles.modulesContentContainer,
                ]}
              >
                <ModuleCheckboxes />
              </div>
              <Button
                variant="primary"
                css={styles.submitBtn}
                data-testid="submit-btn"
              >
                Create organization
              </Button>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default CreateNewOrg;
