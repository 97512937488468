import React, { useMemo } from "react";
import useOrganisation from "src/providers/organisation/hooks";
import {
  hasAdaptiveMusicAccess,
  isModuleVisible,
} from "src/pages/dashboard/utils";
import useAuth from "src/auth/use-auth";
import { Swiper, SwiperSlide } from "swiper/react";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import SwiperCore, { Mousewheel, Pagination, Navigation } from "swiper/core";
import defaultUserPermissions from "src/auth/utils/user-access-permission";
import { lorealProfessionalOrgId } from "src/constants";
import { eApplicationType } from "src/interfaces/auth";
import config from "src/config";
import { StorageKeys } from "src/providers/feature-flags/state/utils";
import { getModulesData } from "./utils";
import SectionTitleAndSubtitle from "./components/section-title";
import ModuleTile from "../module-tile";
import styles from "./styles";
import CarouselControllers from "../carousel-controllers";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

SwiperCore.use([Mousewheel, Pagination, Navigation]);

const ModulesSection = () => {
  const { organisation } = useOrganisation();
  const { organisationId, userPermissions } = useAuth();

  useWindowSize();
  const isMobile = getIsMobile();

  const isCSCMode =
    localStorage.getItem(StorageKeys.CSCMODE) === "on" ||
    (organisation?.enableCscMode ?? false);

  const permissions = useMemo(() => {
    return userPermissions ?? defaultUserPermissions;
  }, [userPermissions]);

  // Hardcoding so the Loreal professional account doesnt show the search tile
  const isLorealProfessional = organisationId === lorealProfessionalOrgId;

  const modulesVisibility = useMemo(() => {
    return {
      search:
        !isLorealProfessional &&
        isModuleVisible(
          organisation,
          permissions.canAccessMusicSubscription || isCSCMode,
          eApplicationType.LibraryFull
        ),
      library: isModuleVisible(
        organisation,
        permissions.canAccessMusicSubscription || isCSCMode,
        eApplicationType.LibraryFull
      ),
      projects: isModuleVisible(
        organisation,
        permissions.canAccessProjects,
        eApplicationType.ProjectsClient
      ),
      resources: isModuleVisible(
        organisation,
        permissions.canAccessMyContent,
        eApplicationType.Resources
      ),
      measurements:
        config.featureToggles.showValidateFeature &&
        isModuleVisible(
          organisation,
          permissions.canAccessValidate,
          eApplicationType.Validate
        ),
      adaptiveMusic: hasAdaptiveMusicAccess(organisation),
      insights: isModuleVisible(
        organisation,
        permissions.canAccessInsights,
        eApplicationType.Insights
      ),
    };
  }, [
    isLorealProfessional,
    organisation,
    permissions.canAccessInsights,
    permissions.canAccessMusicSubscription,
    permissions.canAccessProjects,
    permissions.canAccessValidate,
    permissions.canAccessMyContent,
    isCSCMode,
  ]);

  const modules = useMemo(() => {
    return getModulesData(organisationId, modulesVisibility, isCSCMode);
  }, [modulesVisibility, organisationId, isCSCMode]);

  return (
    <div css={styles.modulesSectionContainer}>
      <div css={styles.modulesHeaderContainer}>
        <SectionTitleAndSubtitle
          title="Active modules"
          subtitle="Dive into productivity with your Songtradr toolkit"
          titleTranslationPrefix=""
        />
        <CarouselControllers />
      </div>
      <div css={styles.carouselContainer}>
        <Swiper
          className="carousel-swiper"
          direction="horizontal"
          mousewheel={{ forceToAxis: true }}
          navigation={{
            nextEl: ".next-button",
            prevEl: ".previous-button",
          }}
          slidesPerView={isMobile ? 1.4 : "auto"}
          spaceBetween={isMobile ? 24 : 48}
          pagination={{ clickable: false }}
          // breakpoints={{
          //   390: {
          //     slidesPerView: 1.5,
          //     spaceBetween: 24,
          //   },
          //   768: {
          //     slidesPerView: 3,
          //     spaceBetween: 24,
          //   },
          //   1024: {
          //     slidesPerView: 4.5,
          //     spaceBetween: 24,
          //   },
          // }}
        >
          {modules.map((module) => {
            return (
              <SwiperSlide className="slideSwipe">
                <ModuleTile
                  key={module.title}
                  title={module.title}
                  description={module.description}
                  link={module.link}
                  externalLink={module.externalLink}
                  openInSameWindow={module.openInSameWindow}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default ModulesSection;
