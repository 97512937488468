import createOrganisation from "src/api/organisation/create-organisation";
import getOrgPermsFromFormData from "src/pages/org-dashboard/components/module-checkboxes/utils";

const createOrgWithSubscriptions = async (
  values: any,
  accessToken: string,
  abortController?: AbortController
) => {
  const createOrgPayload = {
    name: values.companyName,
    contact:
      values.firstName || values.lastName || values.email || values.phone
        ? {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
          }
        : null,
    servicingOrganisation: {
      id: values.servicingOrganisationId,
      email: values.servicingOrganisationEmail,
    },
    isPublicCompany: values.isPublicCompany,
    marketSymbol: values.marketSymbol,
    marketCap: values.marketCap,
    numberOfEmployees: values.numberOfEmployees,
    tikTokFollowerCount: values.tiktokFollowerCount,
    instagramFollowerCount: values.instagramFollowerCount,
    ...(values.parentOrganisationId && {
      parent: {
        id: values.parentOrganisationId,
      },
    }),
    ...(values.holdingOrganisationId && {
      holdingCompany: {
        id: values.holdingOrganisationId,
      },
    }),
    ...(values.currency && {
      currency: values.currency.value,
    }),
    applications: getOrgPermsFromFormData(values),
    enableCommercialMusicSearch: values.enableCommercialMusicSearch,
    licensingOverviewDocumentUrl: values.licensingOverviewDocumentUrl,
    ...(values.naturalLanguageSearchVersion && {
      naturalLanguageSearchVersion: values.naturalLanguageSearchVersion.value,
    }),
    enableBriefSearch: values.enableBriefSearch,
    enablePasscodes: values.enablePasscodes,
    enableAdaptiveMusic: values.enableAdaptiveMusic,
    adaptiveMusic: values.adaptiveMusic,
    enableVideoSync: values.enableVideoSync,
    enableCscMode: values.enableCscMode,
  };

  return createOrganisation(accessToken, createOrgPayload, abortController);
};

export default createOrgWithSubscriptions;
